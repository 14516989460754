import React, { useState } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import Img from "gatsby-image"
import { Dialog, IconButton } from '@material-ui/core'
import { MdClose } from 'react-icons/md'

const Popup = () => {
  const [open, setOpen] = useState(true)
  const handleClose = () => {
    setOpen(false);
  };
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "arcoiris.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"

    >
      <Div>
        <IconButton className='iconito' aria-label="cerrar" onClick={handleClose} variant="contained">
          <MdClose />
        </IconButton>
        <Img className='imagen' fluid={data.placeholderImage.childImageSharp.fluid} />
      </Div>
    </Dialog>
  )
}

export default Popup

const Div = styled.div`
  .imagen{
    width: 200px;
    @media screen and (min-width: 400px){
      width: 350px;
    }
  }
  .iconito{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999999;
  }
`