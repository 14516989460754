import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

import { Grid, Container } from '@material-ui/core'

const Hero = () => {
  const data = useStaticQuery(graphql`
    query HeroImage{
      image: file(relativePath: {eq: "hero-image.png"}) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const HeroContainer = styled.div`
    position: relative;
    background-color: #4c1e08;
    height: 100vh;
    background-size: cover;
    background-image: ${`url(${data.image.childImageSharp.fluid.src})`};
    background-position: top right;
    &:before{
      position: absolute;
      left: 0;
      top: 0;
      right:0;
      bottom: 0;
      background: rgba(115, 57, 16, 0.5);
      content: "";
      z-index: 2;
    }
    .container{
      height: 100%;
      z-index: 3;
      position: relative;
      .grid{
        align-items: flex-end;
        @media (min-width: 768px){
          align-items: center;
        }
      }
    }
  `
  return (
    <HeroContainer className="hero" style={{ height: '100vh' }}>
      <Container className="container">
        <Grid container className="grid" style={{ height: '100%' }}>
          <Grid item xs={12}>
            <h3 style={{ maxWidth: '500px', color: '#fff', lineHeight: '1.3em' }}> El Girasol, es el símbolo del Sol, y simboliza el amor y la admiración, la felicidad, vitalidad, positivismo y energía; pilares sobre los cuales creamos LB</h3>
          </Grid>
        </Grid>
      </Container>
    </HeroContainer>
  )
}


export default Hero